import React from 'react';
import OverviewPanel from './Panels/OverviewPanel';
import useAllMerchandisers from '../../../redux/composableDevice/hook/useAllMerchandisers';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import {
  getAllChildrenGroups,
  getAllGroups,
} from '../../../../../redux/devices/selectors';
import {
  AccordionLight,
  AccordionSummaryLight,
} from '../../../../../theme/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Typography, useTheme } from '@mui/material';
import Region from './Region/Region';
import { PinIcon } from '../../../theme/icons';
import { SCC_TYPE } from '../../../redux/composableDevice/model/utils';
import { IncidentAPIResponseMVP } from '../../../../../redux/incident/api/Incident.model';

const RegionalManagerDashboard = () => {
  const { merchandisers, isLoading } = useAllMerchandisers();
  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) => getAlarmActiveBySelector(state, {}));

  const theme = useTheme();

  const groups = useAppSelector((state) => getAllGroups(state));

  const regions = groups.filter(
    (group) => group.attributes['SCC_TYPE'] === SCC_TYPE.REGION
  );
  const stores = groups.filter(
    (group) => group.attributes['SCC_TYPE'] === SCC_TYPE.STORE
  );

  const alarmsByRegion = new Map<string, IncidentAPIResponseMVP[]>();

  regions.forEach((region) => {
    const children = getAllChildrenGroups([region], []);

    const alarmsInRegion = alarmsActive.filter((alarm) =>
      children.find((group) =>
        group.devices.find((device) => atob(device) === alarm.deviceId)
      )
    );
    alarmsByRegion.set(region.id, alarmsInRegion);
  });

  return (
    <Grid container sx={{ backgroundColor: 'neutral.light', padding: '1em' }}>
      <Grid item xs={12} sm={10}>
        <OverviewPanel
          totalRegions={regions.length}
          totalStores={stores.length}
          alarmsActive={alarmsActive}
          isLoading={isLoading}
          merchandisers={merchandisers}
          isLoadingAlarmsActive={isLoadingAlarmsActive}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        {regions.length > 1
          ? regions.map((region) => (
              <AccordionLight
                key={region.id}
                sx={{ background: 'transparent' }}
                defaultExpanded={true}
              >
                <AccordionSummaryLight
                  id="regions-title"
                  aria-controls="accordion-regions-title"
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ padding: '1rem 0 0 0' }}
                >
                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    gap="1rem"
                    padding={'0 1rem'}
                  >
                    <PinIcon
                      fontSize="1.5rem"
                      color={theme.palette.common.black}
                    />
                    <Typography color="common.black" fontWeight={'600'}>
                      {region.name}
                    </Typography>
                  </Grid>
                </AccordionSummaryLight>
                <Region
                  region={region}
                  merchandisers={merchandisers}
                  alarms={alarmsByRegion.get(region.id) ?? []}
                />
              </AccordionLight>
            ))
          : null}
      </Grid>
    </Grid>
  );
};

export default RegionalManagerDashboard;
