import { useTheme } from '@emotion/react';
import { Group } from '../../../../../../../redux/groups/api/group.model';
import { IncidentAPIResponseMVP } from '../../../../../../../redux/incident/api/Incident.model';
import { Merchandiser } from '../../../../../redux/composableDevice/model/Merchandiser.model';
import { getPolyglot } from '../../../../../../../i18n';
import { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AlarmCard, { SEVERITY } from './AlarmCard';
import React from 'react';
import { AlarmIcon } from '../../../../../theme/icons';
interface AlarmsOverviewProps {
  gridDisplay: boolean;
  alarmsActive: IncidentAPIResponseMVP[];
  cities: Map<
    string,
    {
      city: Group;
      stores: {
        store: Group;
        merchandisers: Merchandiser[];
        alarms: IncidentAPIResponseMVP[];
      }[];
    }
  >;
}
function AlarmOverview(props: AlarmsOverviewProps) {
  const { gridDisplay, alarmsActive, cities } = props;
  const theme = useTheme();
  const polyglot = getPolyglot();
  const citiesArray = Array.from(cities.values());
  const alarms = useMemo(
    () =>
      alarmsActive.map((alarm) => {
        const alarmCity = citiesArray.find((city) =>
          city.stores.some((store) =>
            store.alarms.some((storeAlarm) => storeAlarm.uuid === alarm.uuid)
          )
        );
        const alarmStore = alarmCity?.stores.find((store) =>
          store.alarms.some((storeAlarm) => storeAlarm.uuid === alarm.uuid)
        );
        const alarmMerchandiser = alarmStore?.merchandisers.find(
          (merchandiser) =>
            merchandiser.devices.some(
              (device) => atob(device) === alarm.deviceId
            )
        );

        return {
          title: alarmCity?.city.name ?? '',
          storeName: alarmStore?.store.name ?? '',
          productName: alarmMerchandiser?.name ?? '',
          description: alarm.eventTranslation?.longText ?? '',
          timestamp: alarm.timestamp,
          severity: SEVERITY.HIGH,
          uuid: alarm.uuid,
        };
      }),
    [alarmsActive]
  );

  const content = gridDisplay ? (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {alarms.length ? (
        alarms.map((alarm) => <AlarmCard key={alarm.title} {...alarm} />)
      ) : (
        <Typography>
          {polyglot.t('device.search.message_no_results_found')}
        </Typography>
      )}
    </Box>
  ) : (
    <Box></Box>
  );

  return (
    <>
      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'center'} gap="1rem" padding={'1rem'}>
          <AlarmIcon fontSize="1.5rem" color={theme.palette.common.black} />
          <Typography color="common.black" fontWeight="600">
            {polyglot.t('freshwatch.dashboard.alarms')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </>
  );
}

export default AlarmOverview;
