import React from 'react';
import { Group } from '../../../../../redux/groups/api/group.model';
import { IncidentAPIResponseMVP } from '../../../../../redux/incident/api/Incident.model';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import InfoCard from '../InfoCard/InfoCard';
import { Box, Button, styled, Typography, useTheme } from '@mui/material';
import { getPolyglot } from '../../../../../i18n';

const CityStoresCard = (props: {
  city: Group;
  stores: {
    store: Group;
    merchandisers: Merchandiser[];
    alarms: IncidentAPIResponseMVP[];
  }[];
}) => {
  const { city, stores } = props;

  const polyglot = getPolyglot();
  const theme = useTheme();

  const subheader = (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '4fr 1fr 1fr',
        padding: '0.5rem',
        background: theme.palette.neutral.main,
        color: theme.palette.common.black,
      }}
    >
      <Typography
        color="common.black"
        padding={'0 0 0 1.5rem'}
        textAlign="start"
        fontSize={'1rem'}
      >
        {polyglot.t('freshwatch.dashboard.stores')}
      </Typography>
      <Typography fontSize={'1rem'} color="common.black">
        {polyglot.t('freshwatch.dashboard.merchandisers')}
      </Typography>
      <Typography fontSize={'1rem'} color="common.black">
        {polyglot.t('freshwatch.dashboard.alarms')}
      </Typography>
    </Box>
  );

  return (
    <InfoCard
      sx={{ width: '100%', maxWidth: '100%', margin: 0, height: '100%' }}
      infoTitle={city.name}
      subtitle={subheader}
      disableRipple={true}
      error={stores.some((store) => store.alarms.length > 0)}
    >
      <Box
        sx={{
          height: '100%',
          background: theme.palette.common.white,
          borderRadius: '0 0 1.5rem 1.5rem',
          maxHeight: '300px',
          overflow: 'auto',
        }}
      >
        {stores.length === 0 ? (
          <Typography
            color="common.black"
            component={'p'}
            textAlign="start"
            padding={'1.5rem'}
          >
            {polyglot.t('device.search.message_no_results_found')}
          </Typography>
        ) : (
          stores.map((store) => {
            return (
              <StoreItem key={store.store.id} error={store.alarms.length > 0}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: '4fr 1fr 1fr',
                  }}
                >
                  <Typography
                    variant="h6"
                    textAlign="start"
                    padding={'0 0 0 1rem'}
                  >
                    {store.store.name}
                  </Typography>
                  <Typography variant="h6">
                    {store.merchandisers.length}
                  </Typography>
                  <Typography variant="h6">{store.alarms.length}</Typography>
                </Box>
              </StoreItem>
            );
          })
        )}
      </Box>
    </InfoCard>
  );
};

const StoreItem = styled(Button)<{ error?: boolean }>(
  ({ error, theme }) => `
  width:100%;
  border-radius: 0;
  color: ${theme.palette.common.black};
  background: ${theme.palette.common.white};
  border-bottom: 2px solid ${theme.palette.neutral.main};
  font-weight: 400;
  :hover {
    background: ${theme.palette.primary.light};
    color: ${theme.palette.common.white};
  }
  ${
    error &&
    `
    background: ${theme.palette.error.lightest};
    color: ${theme.palette.error.main};
   :hover {
      background: ${theme.palette.error.light};
      color: ${theme.palette.error.main};
  }
  `
  }
`
);

export default CityStoresCard;
