import { Box, IconButton, Skeleton, Typography, useTheme } from '@mui/material';
import React from 'react';
import {
  AlarmIcon,
  MercIcon,
  PinIcon,
  StoreIcon,
} from '../../../../theme/icons';
import OverviewCard, {
  OverviewCardItem,
} from '../../../../theme/components/OverviewCard/OverviewCard';
import { getPolyglot } from '../../../../../../i18n';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { IncidentAPIResponseMVP } from '../../../../../../redux/incident/api/Incident.model';

interface OverviewPanelProps {
  totalRegions: number;
  totalStores: number;
  merchandisers: Merchandiser[];
  isLoading: boolean;
  alarmsActive: IncidentAPIResponseMVP[];
  isLoadingAlarmsActive: boolean;
}
const OverviewPanel = (props: OverviewPanelProps) => {
  const {
    totalRegions,
    totalStores,
    merchandisers,
    isLoading,
    alarmsActive,
    isLoadingAlarmsActive,
  } = props;
  const theme = useTheme();

  const polyglot = getPolyglot();

  return (
    <OverviewCard sx={{ boxShadow: 2 }}>
      <OverviewCardItem>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <IconButton>
            <PinIcon
              color={`${theme.palette.primary.main}`}
              height={'4.5rem'}
              width={'100%'}
            />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h4" color="common.black">
              {polyglot.t('freshwatch.dashboard.regions')}
            </Typography>
            <Typography
              variant="h6"
              fontSize={'5rem'}
              lineHeight={'5rem'}
              color="common.black"
            >
              {isLoading ? <Skeleton width={80} /> : totalRegions}
            </Typography>
          </Box>
        </Box>
      </OverviewCardItem>
      <OverviewCardItem>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <IconButton disableRipple={true}>
            <StoreIcon
              color={`${theme.palette.primary.main}`}
              height={'4.5rem'}
              width={'100%'}
            />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h4" color="common.black">
              {polyglot.t('freshwatch.dashboard.stores')}
            </Typography>
            <Typography
              variant="h6"
              fontSize={'5rem'}
              lineHeight={'5rem'}
              color="common.black"
            >
              {isLoading ? <Skeleton width={80} /> : totalStores}
            </Typography>
          </Box>
        </Box>
      </OverviewCardItem>
      <OverviewCardItem>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <IconButton disableRipple={true}>
            <MercIcon
              color={`${theme.palette.primary.main}`}
              height={'4.5rem'}
              width={'100%'}
            />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h4" color="common.black">
              {polyglot.t('freshwatch.dashboard.merchandisers')}
            </Typography>
            <Typography
              variant="h6"
              fontSize={'5rem'}
              lineHeight={'5rem'}
              color="common.black"
            >
              {isLoading ? <Skeleton width={80} /> : merchandisers.length}
            </Typography>
          </Box>
        </Box>
      </OverviewCardItem>
      <OverviewCardItem>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <IconButton disableRipple={true}>
            <AlarmIcon
              color={`${theme.palette.error.main}`}
              height={'4.5rem'}
              width={'100%'}
            />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h4" color="common.black">
              {polyglot.t('freshwatch.dashboard.alarms')}
            </Typography>
            <Typography
              variant="h6"
              fontSize={'5rem'}
              lineHeight={'5rem'}
              color="common.black"
            >
              {isLoadingAlarmsActive ? (
                <Skeleton width={80} />
              ) : (
                alarmsActive.length
              )}
            </Typography>
          </Box>
        </Box>
      </OverviewCardItem>
    </OverviewCard>
  );
};

export default OverviewPanel;
