import React from 'react';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { getAllChildrenGroups } from '../../../../../../redux/devices/selectors';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { IncidentAPIResponseMVP } from '../../../../../../redux/incident/api/Incident.model';
import { SCC_TYPE } from '../../../../redux/composableDevice/model/utils';
import { Box, Typography, useTheme } from '@mui/material';
import { getPolyglot } from '../../../../../../i18n';
import StoreOverviewWrapper from '../../../../theme/components/Store/StoreOverviewWrapper/StoreOverviewWrapper';
import { MapWithDevices } from './MapRegion';
import MerchandiserOverview from '../PanelSection/MerchandiserOverview/MerchandiserOverview';
import AlarmOverview from '../PanelSection/AlarmOverview/AlarmOverview';

const Region = ({
  region,
  merchandisers,
  alarms,
}: {
  region: Group;
  merchandisers: Merchandiser[];
  alarms: IncidentAPIResponseMVP[];
}) => {
  const regionChildren = getAllChildrenGroups([region], []);
  const cities = regionChildren.filter(
    (group) => group.attributes['SCC_TYPE'] === SCC_TYPE.CITY
  );
  const cityInformation = new Map<
    string,
    {
      city: Group;
      stores: {
        store: Group;
        merchandisers: Merchandiser[];
        alarms: IncidentAPIResponseMVP[];
      }[];
    }
  >();

  const polyglot = getPolyglot();

  cities.forEach((city) => {
    const children = getAllChildrenGroups(city.childGroups, []);
    const stores = children.filter(
      (child) => child.attributes['SCC_TYPE'] === SCC_TYPE.STORE
    );
    const storesList = stores.map((store) => {
      const storesGroups = getAllChildrenGroups(store.childGroups, []);

      const storeMerchandisers = merchandisers.filter((merchandiser) =>
        storesGroups.find((group) => group.id === merchandiser.id)
      );
      const alarmsDevices = alarms.filter((alarm) =>
        storeMerchandisers.find((merchandiser) =>
          merchandiser.devices.find((device) => atob(device) === alarm.deviceId)
        )
      );

      return {
        store,
        merchandisers: storeMerchandisers,
        alarms: alarmsDevices,
      };
    });

    cityInformation.set(city.id, {
      city,
      stores: storesList,
    });
  });

  const allDevices = [...cityInformation.values()]
    .map((item) =>
      item.stores.reduce(
        (prev, curr) =>
          prev.concat(...curr.merchandisers.map((m) => m.devices)),
        [] as string[]
      )
    )
    .flat();

  const noCities = (
    <Typography>
      {polyglot.t('device.search.message_no_results_found')}
    </Typography>
  );

  if (!cityInformation.size) {
    return noCities;
  }

  return (
    <>
      {/* List of Cities with the number of merchandisers */}
      <StoreOverviewWrapper storeWithMerchandisers={cityInformation} />
      {/* Map Overview with the merchandisers in the city */}
      <Box sx={{ height: '30vh', overflow: 'hidden' }}>
        <MapWithDevices devicesIds={Array.from(new Set(allDevices))} />
      </Box>
      {/* Merchandisers Overview */}
      <MerchandiserOverview cityInformation={cityInformation} />
      {/* Alarms */}
      <AlarmOverview gridDisplay={true} alarmsActive={alarms} cities={cityInformation} />
    </>
  );
};

export default Region;
